import React, { useState } from "react";
import styles from "./questions.module.css";
import { FaCircle } from "react-icons/fa";
import { ReactComponent as Vector } from "../../../assets/vecor.svg";
import sample from "../../../assets/quiz-background.png";
import { ReactComponent as ThumbsUp } from "../../../assets/thumbsup.svg";
import { ReactComponent as ThumbsDown } from "../../../assets/thumbsdown.svg";

const Questions = ({ setStage, result, setResult, quiz }) => {
  const [page, setPage] = useState(0);

  const handlePageChange = (topic, option) => {
    if (page < quiz?.question?.length - 1) {
      setResult([...result, { topic: topic, user_answer: option }]);
      setPage((prev) => prev + 1);
    } else if (page === quiz?.question?.length - 1) {
      setResult([...result, { topic: topic, user_answer: option }]);
      setStage("submit");
    }
  };
  return (
    quiz?.question?.length > 0 && (
      <div className={styles["question-container"]}>
        <div className={styles["swiper-container"]}>
          <div className={styles["swiper-indicator"]}>
            {quiz?.question?.map((item, index) => (
              <FaCircle
                className={styles["bullet"]}
                color={index === page ? "#00ABFF" : "#ffffff"}
              />
            ))}
          </div>
          {quiz?.question
            ?.filter((item, index) => index === page)
            .map((item) => (
              <div key={item.id} className={styles["swiper-questions"]}>
                <div className={styles["details"]}>
                  <img src={quiz?.banner?.url ?? sample} alt="" />
                  <div className={styles["questions"]}>
                    <Vector />
                    <span className={styles["start"]}>Are You</span>
                    <div className={styles["title"]}>{item?.question}?</div>
                    <div className={styles["description"]}>
                      {item?.description}
                    </div>
                  </div>
                  <div className={styles["overlay"]}></div>
                </div>
                <div className={styles["option-container"]}>
                  <div
                    onClick={() => handlePageChange(item?.topic, "PROS")}
                    className={`${styles["search-preference-score"]} ${styles["min"]}`}
                  >
                    <ThumbsDown />
                  </div>
                  <div
                    onClick={() => handlePageChange(item?.topic, "CONS")}
                    className={`${styles["search-preference-score"]} ${styles["max"]}`}
                  >
                    <ThumbsUp />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  );
};

export default Questions;
