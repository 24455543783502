import React, { useEffect, useState } from "react";
import { ReactComponent as RedSplash } from "../../../assets/red-splash.svg";
import { ReactComponent as Pros } from "../../../assets/pros.svg";
import { ReactComponent as Cons } from "../../../assets/cons.svg";
import Splash from "../../../assets/quiz-splash.png";
import styles from "./home.module.css";
import { useParams } from "react-router-dom";
import { getQuizzes } from "../../../api";
const QuizHome = () => {
  const [quizzes, setQuizzes] = useState();
  const params = useParams();
  useEffect(() => {
    getQuizzes()
      .then((res) => {
        setQuizzes(res);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className={styles["container"]}>
      <div className={`${styles["quiz-container"]} container`}>
        <div className={styles["quiz-detail"]}>
          <h1>Kliqe Quizzes</h1>
          <p>
            Take our quiz to find your perfect match. You’d be the perfect match
            for someone.
          </p>
          <RedSplash className={styles["red-splash"]} />
        </div>
        <div className={styles["quiz-splash"]}>
          <img className={styles["splash"]} src={Splash} alt="" />
          <Cons className={`${styles["scores"]} ${styles["cons"]}`} />
          <Pros className={`${styles["scores"]} ${styles["pros"]}`} />
        </div>
      </div>
      <div className={`${styles["quiz-categories"]}`}>
        <div className={`${styles["category-container"]} container`}>
          {quizzes ? (
            quizzes?.map((item) => (
              <div key={item.id} className={styles["quiz-category"]}>
                <img
                  className={styles["category-image"]}
                  src={item?.image?.url ?? Splash}
                  alt=""
                />
                <div>
                  <span className={styles["category-title"]}>{item.title}</span>
                  <p className={styles["category-detail"]}>
                    {item.description}
                  </p>
                  <a href={`quiz/${item.id}`} className={styles["start"]}>
                    Start the Quiz
                  </a>
                  <a
                    href={`quiz/${item.id}`}
                    className={styles["start-quiz"]}
                  ></a>
                </div>
              </div>
            ))
          ) : (
            <div className={`${styles["spinner"]} small-spinner-container`}>
              <div className="spinner"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizHome;
