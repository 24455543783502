import React from "react";
import styles from "./banner.module.css";
const QuizBanner = ({ stats = false }) => {
  return (
    <div
      className={`${styles["banner"]} ${stats ? styles["stats-banner"] : ""}`}
    >
      <div className={styles["elipse-container"]}>
        <div className={styles["elipse-small"]}></div>
        <div className={styles["elipse"]}></div>
      </div>
    </div>
  );
};

export default QuizBanner;
