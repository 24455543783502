import React from "react";
import QuizHome from "../../../components/quiz/home/home";
import QuizLayout from "../../../components/layout/quizLayout/quizLayout";
const Quiz = () => {
  return (
    <QuizLayout>
      <QuizHome />
    </QuizLayout>
  );
};

export default Quiz;
