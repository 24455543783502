import React, { useEffect, useState } from "react";
import styles from "./statistics.module.css";
import NewNavbar from "../../components/NewNavbar/NewNavbar";
import QuizBanner from "../../components/quiz/banner/banner";
import KliqeLogo from "../../assets/images/kliqe-logo-white.svg";
import { ReactComponent as ThumbsUp } from "../../assets/thumbsup.svg";
import { ReactComponent as ThumbsDown } from "../../assets/thumbsdown.svg";
import { getStatistics } from "../../api";
import Footer from "../../components/NewFooter/NewFooter";
const Statistics = () => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const renderScore = (type, pro, against) => {
    if (against < pro) {
      if (type === "PROS") {
        return (
          <div
            className={`${styles["search-preference-score"]} ${styles["max-pro"]}`}
          >
            <ThumbsUp />
          </div>
        );
      } else {
        return (
          <div
            className={`${styles["search-preference-score"]} ${styles["min-cons"]}`}
          >
            <ThumbsDown />
          </div>
        );
      }
    } else if (against > pro) {
      if (type === "PROS") {
        return (
          <div
            className={`${styles["search-preference-score"]} ${styles["min-pro"]}`}
          >
            <ThumbsUp />
          </div>
        );
      } else {
        return (
          <div
            className={`${styles["search-preference-score"]} ${styles["max-cons"]}`}
          >
            <ThumbsDown />
          </div>
        );
      }
    }
  };
  const calculatePercentage = (proCount, againstCount) => {
    const totalCount = proCount + againstCount;
    if (totalCount === 0) {
      return 0;
    }
    const percentage = (proCount / totalCount) * 100;
    return Math.round(percentage);
  };
  useEffect(() => {
    setLoading(true);
    getStatistics()
      .then((res) => {
        setLoading(false);
        setStats(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className={styles["statistics"]}>
      <NewNavbar preference={true} />
      <div className={`${styles["stats-container"]} container`}>
        <h4>KLIQE STATISTICS</h4>
        <div className={styles["stats-details"]}>
          <div className={styles["stats-wrapper"]}>
            {!loading ? (
              stats?.map((item) => (
                <div className={styles["stats"]}>
                  <QuizBanner stats={true} />
                  <div className={styles["info-container"]}>
                    <img src={KliqeLogo} alt="logo" height={30} width={110} />
                    <div className={styles["stats-info"]}>
                      <div className={styles["stats-info-container"]}>
                        <div
                          className={styles["stats-score"]}
                          style={{
                            opacity:
                              item?.against_count < item?.pro_count
                                ? "0.6"
                                : "1",
                          }}
                        >
                          <div>
                            {renderScore(
                              "CONS",
                              item?.pro_count,
                              item?.against_count
                            )}
                          </div>
                          <div
                            style={{
                              height: `${calculatePercentage(
                                item?.against_count,
                                item?.pro_count
                              )}%`,
                            }}
                            className={styles["stats-bar-cons"]}
                          ></div>
                          <div
                            className={`${styles["stats-bar-cons"]} ${styles["percentage"]}`}
                          >
                            {calculatePercentage(
                              item?.against_count,
                              item?.pro_count
                            )}
                            %
                          </div>
                        </div>
                        <div
                          className={styles["stats-score"]}
                          style={{
                            opacity:
                              item?.pro_count < item?.against_count
                                ? "0.6"
                                : "1",
                          }}
                        >
                          <div>
                            {renderScore(
                              "PROS",
                              item?.pro_count,
                              item?.against_count
                            )}
                          </div>
                          <div
                            style={{
                              height: `${calculatePercentage(
                                item?.pro_count,
                                item?.against_count
                              )}%`,
                            }}
                            className={styles["stats-bar-pros"]}
                          ></div>
                          <div
                            className={`${styles["stats-bar-pros"]} ${styles["percentage"]}`}
                          >
                            {calculatePercentage(
                              item?.pro_count,
                              item?.against_count
                            )}
                            %
                          </div>
                        </div>
                      </div>
                      <div className={styles["stats-preference"]}>
                        <span className={styles["preference"]}>
                          {item?.topic}
                        </span>
                        {/* <span className={styles["date"]}>22/22/22</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={`${styles["spinner"]} small-spinner-container`}>
                <div className="spinner"></div>
              </div>
            )}
          </div>
          <div className={styles["content"]}>
            <p>
              Aggregated information on all Kliqe Users world wide. Percentages
              are based on total user count and may not add up to 100% since not
              every user enters every value.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Statistics;
