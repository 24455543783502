import React, { useEffect, useState } from "react";
import Splash from "../../../assets/quiz-splash-large.png";
import { ReactComponent as Icon } from "../../../assets/quiz.svg";
import styles from "./startPage.module.css";
import QuizLayout from "../../../components/layout/quizLayout/quizLayout";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { getQuizzes } from "../../../api";

const QuizStartPage = () => {
  const [quiz, setQuiz] = useState();
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (params?.category) {
      getQuizzes(params.category)
        .then((res) => {
          if (res?.length > 0) {
            setQuiz(res[0]);
          } else {
            navigate("/quiz");
          }
        })
        .catch((err) => console.log(err));
    }
  }, [params.category]);
  return (
    <QuizLayout>
      {quiz ? (
        <div className={`${styles["quiz-container"]} container`}>
          <div className={styles["quiz-detail"]}>
            <a className={styles["back"]} href="/quiz">
              <FaAngleLeft color="#ffffff" />
              All Quizzes
            </a>
            <h1>{quiz?.title}</h1>
            <p>{quiz?.description}</p>
            <button
              className={`${styles["quiz-button"]} ${styles["primary"]}`}
              onClick={() => navigate("questions")}
            >
              <Icon />
              <span>Let's Go</span>
              <Icon />
            </button>
          </div>
          <div className={styles["quiz-splash"]}>
            <img
              className={styles["splash"]}
              src={quiz?.splash?.url ?? Splash}
              alt=""
            />
          </div>
          <button
            className={`${styles["quiz-button"]} ${styles["bottom"]}`}
            onClick={() => navigate("questions")}
          >
            <Icon />
            <span>Let's Go</span>
            <Icon />
          </button>
        </div>
      ) : (
        <div className={`${styles["spinner"]} small-spinner-container`}>
          <div className="spinner"></div>
        </div>
      )}
    </QuizLayout>
  );
};

export default QuizStartPage;
