import React, { useState, useContext, useEffect } from "react";
import styles from "./sign_in.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { login, resendConfirmationEmail } from "../../../api";
import { useNavigate } from "react-router-dom";
import { Loading } from "react-loading-dot";
import "../popup.css";

import AuthContext from "../../../store/authContext";
function SignIn(props) {
  const [isOpen, setIsOpen] = useState(true);
  const authCtx = useContext(AuthContext);
  const [resendLoading, setResendLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [resendEmail, setResendEmail] = useState(false);

  const handleClose = () => {
    document.body.classList.remove("lock-scroll");
    setIsOpen(false);
    props.onButtonClickClose();
  };

  const handleForgotPassword = () => {
    props.callForgotPassword();
  };
  const handleResendConfirmationEmail = () => {
    setResendLoading(true);
    resendConfirmationEmail({ email })
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setResendLoading(false);
      });
  };
  const handleAllOptions = () => {
    props.propHandleAllOptions();
  };

  const handleSubmit = () => {
    if (!email || !password) {
      setEmailError("Please fill all the fields");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email");
      return;
    }
    
    setIsLoading(true);
    login({ username: email, password: password  })
      .then((res) => {
        if (!res.error) {
          setResendEmail(false);
          // Send a message to the content script
          localStorage.setItem("refresh_token", res.refresh_token);
          window.postMessage(
            {
              type: "TriggerScore_Login",
              access: res.access_token,
              refresh: res.refresh_token,
            },
            "*"
          );

          authCtx.login(res.access_token);
          document.body.classList.remove("lock-scroll");
          navigate("/preferences");
        } else if (res.error === "User Not Verified") {
          setEmailError(
            "Email verification is pending. Check your inbox or resend the confirmation email."
          );
          setResendEmail(true);
        } else {
          setEmailError("Invalid email or password");
        }
      })
      .catch((err) => {
        setEmailError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleBlur = () => {
    if (!validateEmail(email) && email.length > 0) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  };

  useEffect(() => {
    if (isOpen == true) document.body.classList.add("lock-scroll");
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className={styles.popup}>
          <div className={`${styles.popupContent}`}>
            <button className={styles.popupClose} onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h3>Sign in with email.</h3>
            <p className={`${styles.topDescription}`}>
              Enter the email address associated with your account and your
              password.
            </p>
            {!email ? <h6>Your email </h6> : <h6></h6>}
            <input
              className={styles.inputField}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={handleBlur}
            />
            {!password ? <h6>Your password </h6> : <h6></h6>}
            <input
              type="password"
              className={styles.inputField}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {emailError && (
              <>
                <p className={styles.emailError}>{emailError}</p>
                {resendEmail && (
                  <>
                    <button
                      className={styles.resendButton}
                      onClick={handleResendConfirmationEmail}
                      disabled={resendLoading}
                    >
                      {resendLoading ? (
                        <div className="loading-animation">
                          <div className="loading-animation">
                            <Loading
                              background="white"
                              size="5px"
                              margin="5px"
                            />
                          </div>
                        </div>
                      ) : (
                        "Resend Confirmation Email"
                      )}
                    </button>
                  </>
                )}
              </>
            )}
            <button
              className={styles.button}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="loading-animation">
                  <div className="loading-animation">
                    <Loading background="white" size="5px" margin="5px" />
                  </div>
                </div>
              ) : (
                "Sign in"
              )}
            </button>
            <p onClick={handleForgotPassword} style={{ cursor: "pointer" }}>
              Forgot Password?
            </p>
            <p className={`${styles.backToLogin}`} onClick={handleAllOptions}>
              <span className={styles.backArrow}></span>
              All Sign up options
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default SignIn;
