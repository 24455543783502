import React, { useState } from "react";
import { ReactComponent as Icon } from "../../../assets/quiz.svg";
import styles from "./submit.module.css";
import { Loading } from "react-loading-dot";
const QuizSubmit = ({ setStage, email, setEmail, quiz, submitQuiz,isLoading }) => {
  const [error, setError] = useState(false);
  const handeleSubmit = () => {
    if (isValidEmail(email)) {
      submitQuiz();
    } else {
      setError(true);
    }
  };
  const onChange = (e) => {
    setEmail(e.target.value);
    setError(e.target.value ? false : true);
  };
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  return (
    <div className={styles["submit-container"]}>
      <div
        className={styles["form-container"]}
        style={{ backgroundImage: `url(${quiz?.banner?.url})` }}
      >
        <div className={styles["form-overlay"]}></div>
        <div className={styles["form-detail-container"]}>
          <h3>
            Who is your
            <br /> {quiz?.subTitle}?
          </h3>
          <span>Enter your Email Address to find out:</span>
          <div className={styles["input"]}>
            <input
              onChange={onChange}
              className={styles["submit"]}
              placeholder="Your Email Address"
            />
            {error && (
              <p className={styles["error"]}>Please enter a valid email</p>
            )}
          </div>

          <p className={styles["description"]}>
            By submitting your email address, a free Kliqe account will be
            created for you.
          </p>
        </div>
      </div>
      <button
        className={`${styles["quiz-button"]} ${styles["bottom"]}`}
        onClick={handeleSubmit}
      >
        <Icon />
        {isLoading ? (
          <div className="loading-animation">
            <div className="loading-animation">
              <Loading background="white" size="5px" margin="5px" />
            </div>
          </div>
        ) : (
          <span>Submit</span>
        )}
        <Icon />
      </button>
    </div>
  );
};

export default QuizSubmit;
