import React, { useState } from "react";
import styles from "./result.module.css";
import { ReactComponent as ThumbsUp } from "../../../assets/thumbsup.svg";
import { ReactComponent as ThumbsDown } from "../../../assets/thumbsdown.svg";
import { ReactComponent as Copy } from "../../../assets/copy-link.svg";
import { ReactComponent as Instagram } from "../../../assets/copy-insta.svg";
import { ReactComponent as Twitter } from "../../../assets/copy-twitter.svg";
import { FaFacebook, FaFacebookF, FaRegUser, FaSortDown } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { TbBrandFacebook } from "react-icons/tb";
const QuizResult = ({ result, quiz, setStage, isResultPage }) => {
  const [copySuccess, setCopySuccess] = useState("");
  const location = useLocation();
  const url = `${window.location.origin}/quiz/result/${result?.response_id}`;
  const links = [
    { icon: <Copy />, link: "copy" },
    {
      icon: <Twitter />,
      link: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        "text"
      )}&url=${encodeURIComponent(url)}`,
    },
    {
      icon: <FaFacebookF />,
      link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url
      )}`,
    },
  ];
  const renderScore = (value) => {
    if (value === "PROS") {
      return (
        <div
          className={`${styles["search-preference-score"]} ${styles["max"]}`}
        >
          <ThumbsUp />
        </div>
      );
    } else {
      return (
        <div
          className={`${styles["search-preference-score"]} ${styles["min"]}`}
        >
          <ThumbsDown />
        </div>
      );
    }
  };
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopySuccess("Copied!");
      setTimeout(() => {
        setCopySuccess(null);
      }, 4000);
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };
  const handleShare = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div className={styles["result-container"]}>
      <div
        className={styles["result-match"]}
        style={{
          backgroundImage: !isResultPage ? `url(${quiz?.banner?.url})` : "none",
        }}
      >
        <div className={styles["result-overlay"]}></div>
        <div className={styles["match"]}>
          <div className={styles["perfect-match"]}>
            <span>You&apos;re perfect match is:</span>
            <h2>{result?.name}</h2>
          </div>
          <div className={styles["image-container"]}>
            <img src={result?.image_url} alt="" />
          </div>
          <div className={styles["share"]}>
            {isResultPage ? (
              <a href="/quiz" className={styles["retake"]}>
                Take your test
              </a>
            ) : (
              <span
                onClick={() => setStage("questions")}
                className={styles["retake"]}
              >
                Retake
              </span>
            )}
            {!isResultPage && (
              <div className={styles["social-media"]}>
                {links.map((item) => (
                  <div
                    title={item.link === "copy" && "Copy Link"}
                    className={styles["icons"]}
                    onClick={() =>
                      item.link === "copy"
                        ? copyToClipboard()
                        : handleShare(item.link)
                    }
                  >
                    {" "}
                    {item?.icon}
                  </div>
                ))}
                {copySuccess && (
                  <span className={styles["copy"]}>{copySuccess}</span>
                )}
              </div>
            )}
            <div className={styles["detailed-result"]}>
              <FaSortDown />
              <span>Results in details</span>
              <FaSortDown />
            </div>
          </div>
        </div>
      </div>
      <div className={styles["result-score"]}>
        <table>
          <thead>
            <tr style={{ border: "1px solid black" }}>
              <th align="left" style={{ width: "80%" }}>
                Your Alignment
              </th>
              <th align="center" style={{ width: "10%" }}>
                <div className={styles["user"]}>
                  <FaRegUser color="#B2B6BB" />
                  <span>{isResultPage ? "Participant" : "You"}</span>
                </div>
              </th>
              <th align="center" style={{ width: "10%" }}>
                <div className={styles["user"]}>
                  <img src={result?.image_url} alt="" />
                  <span>{result?.name}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {result?.affinities &&
              Object?.entries(result?.affinities)?.map(
                ([key, value], index) => (
                  <tr key={index}>
                    <td>{key}</td>
                    <td align="center">{renderScore(value?.persona)}</td>
                    <td align="center">{renderScore(value?.user_response)}</td>
                  </tr>
                )
              )}
          </tbody>
        </table>
        <div>
          <span>How is this calculated?</span>
          <p className={styles["detail"]}>
            We monitor and gather information from a wide range of sources,
            including press releases, brand-published statements, white papers,
            and social media activities. By aggregating and analyzing this data,
            Kliqe ensures a holistic view of a brand&apos;s public stance on
            issues that matter to conscious consumers like you, so you can use
            your purchasing power to promote long-term, sustainable progress.
          </p>
        </div>
      </div>{" "}
      {!isResultPage && (
        <div className={styles["share-quiz"]}>
          <span>share this Quiz:</span>
          <div className={styles["social-media"]}>
            {links.map((item) => (
              <div
                className={`${styles["icons"]} ${styles["footer"]}`}
                onClick={() =>
                  item.link === "copy"
                    ? copyToClipboard()
                    : handleShare(item.link)
                }
              >
                {" "}
                {item?.icon}
              </div>
            ))}

            {copySuccess && (
              <span className={styles["copy"]}>{copySuccess}</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizResult;
