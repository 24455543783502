import axiosInstance from "./axiosInstance";
const baseCMSURL = "https://blog-api.kliqe.com/api/graphql";

export const login = (data) => {
  return axiosInstance.post("/user_login_v4", data);
};

// Generate token for magicLink auth
export const generateToken = (email) => {
  return axiosInstance.post("/generate_token", { email: email });
};

export const register = (formData) => {
  return axiosInstance.post("/google_signup_v2", formData);
};
export const topics = () => {
  return axiosInstance.get("/get_topics_v2");
};

export const magicLinkSignUp = (token, data) => {
  return axiosInstance.post(`magic_link_signup_v2?token=${token}`, data);
};

export const verifyMagicLink = (token) => {
  return axiosInstance.get(`/magic_link_authentication_v2?token=${token}`);
};

export const googleSignUp = (token) => {
  return axiosInstance.post("/verify_email", token);
};

export const resendConfirmationEmail = (data) => {
  return axiosInstance.post("/resend_confirmation_email", data);
};
export const preference = (data) => {
  return axiosInstance.post("/user_preferences", data);
};

export const getSelectedTags = (token) => {
  return axiosInstance.post("/current_user_details", token);
};
export const PopularCompanyScoreForNotLogin = (data) => {
  return axiosInstance.post("/batched_company_lookout_for_not_login", data);
};
export const DataForLoggedInUsersInSearch = (data, token) => {
  return axiosInstance.post("/batched_company_lookout_for_login", data, token);
};
export const SaveNoResultsSearch = (data) => {
  return axiosInstance.post("/trending_2", data);
};
export const save_search = (data) => {
  return axiosInstance.post("/trending_searches_v2", data);
};
export const get_domain_from_name = (data) => {
  return axiosInstance.post("/get_domain_from_name", data);
};
export const GetUserDetails = () => {
  return axiosInstance.get("/current_user_details");
};
export const GetScoresForLoggedInusers = (data, token) => {
  const newheader = {
    ...axiosInstance.defaults.headers,
    Token: token,
  };
  return axiosInstance.post("/batched_company_lookup_v2", data, newheader);
};
export const SearchForNotLogin = (data) => {
  return axiosInstance.post("/search_for_not_login", data);
};
export const TypeAhead = (query) => {
  return axiosInstance.get("/type_ahead?query=" + query);
};
export const TrendingSearches = (token) => {
  return axiosInstance.get("/trending_searches_v2");
};
export const sendEmail = async (email) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/send_email`;
  const response = await axiosInstance.post(url, {
    email: email,
  });
  // console.log(response);
  return response;
};
export const sendEmail2 = async (email) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/send_confirmation_mail`;
  const response = await axiosInstance.post(url, {
    email: email,
    baseUrl: window.location.origin,
  });
  // console.log(response);
  return response;
};
export const verifyUserNew = async (usertoken) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/verify_new_user`;
  const response = await axiosInstance.post(url, {
    usertoken: usertoken,
  });
  // console.log(response);
  return response;
};
export const resetPass = async (token, password) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/reset_password`;
  const response = await axiosInstance.post(url, {
    token: token,
    password: password,
  });
  // console.log(response);
  return response;
};

export const getBlogs = async (page, take) => {
  const offset = (page - 1) * take;

  try {
    // const apiUrl = "http://localhost:3000/api/graphql";
    const query = `
        query Query($take:Int! $offset:Int!){
          posts(where: { status: { equals: "published" } }
            take:$take,
            skip:$offset,
            orderBy: [{ publishedAt:desc }]
            ){
          id
          publishedAt
          status
          tags {
            name
          }
          brands {
            name
          }
          title
          seoTitle
          slug
          contentBlocks {
            topicBanner {
              url
            }
            subContentBlocks {
              title
              content {
                document
              }
            }
            title
          }
          authors {
            email
            name
            authorImage {
              url
            }
          }
          banner {
            url
          }
          description {
            document
          }
          seoDescription
          category{
            name
            slug
          }
        }
      }
      `;

    const headers = {
      "Content-Type": "application/json",
    };
    const variables = {
      take,
      offset,
    };

    const options = {
      method: "POST",
      headers,
      body: JSON.stringify({ query, variables }),
    };

    const response = await fetch(baseCMSURL, options);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }

    const data = await response.json();
    const blogData = data.data.posts;

    return blogData;
  } catch (error) {
    throw error;
  }
};

export const getBlogDetailsbySlug = async (slug) => {
  try {
    // const apiUrl = "http://localhost:3000/api/graphql";

    const query = `
      query Query($slug: String!) {
        posts (where: { slug: { equals: $slug } }){
          id
          publishedAt
          status
          tags {
            name
          }
          brands {
            name
          }
          title
          seoTitle
          slug
          contentBlocks {
            topicBanner {
              url
            }
            subContentBlocks {
              title
              content {
                document
              }
            }
            title
          }
          authors {
            email
            name
            authorImage {
              url
            }
          }
          banner {
            url
          }
          description {
            document
          }
          seoDescription
          category{
            name
            slug
          }
        }
      }
      `;

    const headers = {
      "Content-Type": "application/json",
    };

    const variables = {
      slug,
    };

    const options = {
      method: "POST",
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    };

    const response = await fetch(baseCMSURL, options);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }

    const data = await response.json();
    const blogDetails = data.data.posts;

    return blogDetails;
  } catch (error) {
    throw error;
  }
};

export const getBlogDetailsbyCategory = async (slug, page, take) => {
  const offset = (page - 1) * take;
  try {
    // const apiUrl = "http://localhost:3000/api/graphql";

    const query = `
      query Query($slug: String! $take:Int! $offset:Int!) {
        posts (where: { category: {slug: { contains: $slug } },status: { equals: "published" }} take:$take skip:$offset orderBy: [{ publishedAt:desc }]) {
          id
          publishedAt
          status
          tags {
            name
          }
          brands {
            name
          }
          title
          seoTitle
          slug
          contentBlocks {
            topicBanner {
              url
            }
            subContentBlocks {
              title
              content {
                document
              }
            }
            title
          }
          authors {
            email
            name
            authorImage {
              url
            }
          }
          banner {
            url
          }
          description {
            document
          }
          seoDescription
          category{
            name
            slug
          }
        }
      }
      `;

    const headers = {
      "Content-Type": "application/json",
    };

    const variables = {
      slug,
      take,
      offset,
    };

    const options = {
      method: "POST",
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    };

    const response = await fetch(baseCMSURL, options);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }

    const data = await response.json();
    const blogDetails = data.data.posts;

    return blogDetails;
  } catch (error) {
    throw error;
  }
};

export const getPostCount = async (slug) => {
  try {
    // const apiUrl = "http://localhost:3000/api/graphql";
    const query = `
        query ${slug ? "GetPostCount($slug: String!)" : ""} {
          postsCount${
            slug
              ? '(where: { category: {slug: { equals: $slug } }, status: { equals: "published" }})'
              : '(where: { status: { equals: "published" }})'
          }
        }
`;

    const headers = {
      "Content-Type": "application/json",
    };

    const variables = {
      slug,
    };

    const options = {
      method: "POST",
      headers,
      body: JSON.stringify({ query, variables }),
    };

    const response = await fetch(baseCMSURL, options);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }

    const data = await response.json();
    const postCount = data.data.postsCount;

    return postCount;
  } catch (error) {
    throw error;
  }
};

export const getBlogDetailsByIndex = async (index, publishedAt) => {
  try {
    // const apiUrl = "http://localhost:3000/api/graphql";

    const query = `
    query Query($publishedAt: DateTime){
      posts(where: { publishedAt: { ${
        index ? "lt" : "gt"
      }: $publishedAt },status: { equals: "published" } },orderBy: [{ publishedAt:${
      index ? "desc" : "asc"
    } }],take:1) {
          id
          publishedAt
          status
          tags {
            name
          }
          brands {
            name
          }
          title
          seoTitle
          slug
          contentBlocks {
            topicBanner {
              url
            }
            subContentBlocks {
              title
              content {
                document
              }
            }
            title
          }
          authors {
            email
            name
            authorImage {
              url
            }
          }
          banner {
            url
          }
          description {
            document
          }
          seoDescription
          category{
            name
            slug
          }
        }
      }
      `;

    const headers = {
      "Content-Type": "application/json",
    };

    const variables = {
      publishedAt,
    };

    const options = {
      method: "POST",
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    };

    const response = await fetch(baseCMSURL, options);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }

    const data = await response.json();
    const blogDetails = data.data.posts;

    return blogDetails;
  } catch (error) {
    throw error;
  }
};

export const getConcepts = async (slug) => {
  try {
    const query = `
      query Query($slug: String!) {
        concepts (where: { slug: { equals: $slug } }) {
          id
          publishedAt
          overline
          title
          seoTitle
          slug
          preferenceLabel
          preference
          preferenceSentiment
          description {
            document
          }
          seoDescription
          image {
            url
          }
          CTAs {
            link
            text
            target
          }
          trustSigns {
            title
            rating
          }
          detailedDescription {
            title
            description {
              document
            }
            image {
              url
            }
            imageOrVideoLink
          }
        }
      }
      `;

    const headers = {
      "Content-Type": "application/json",
    };

    const variables = {
      slug,
    };

    const options = {
      method: "POST",
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    };

    const response = await fetch(baseCMSURL, options);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }

    const data = await response.json();
    const conceptDetails = data.data.concepts;

    return conceptDetails;
  } catch (error) {
    throw error;
  }
};
export const GetSearchResults = (data, token) => {
  return axiosInstance.post("/batched_company_lookup_v4_stage", data);
};
export const getQuizzes = async (id) => {
  try {
    const query = `
    query Query($id:ID) {
      quizzes(where: { id: { equals: $id } }) {
        id
        image {
          url
        }
        title
        subTitle
        description
        banner {
          url
        }
        splash {
          url
        }
        question {
          id
          question 
          topic
          description
          image {
            url
          }
        }
      }
    }
    `;

    const headers = {
      "Content-Type": "application/json",
    };

    const variables = {
      id,
    };
    const options = {
      method: "POST",
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    };

    const response = await fetch(baseCMSURL, options);

    if (!response.ok) {
      const errorData = await response.json();
      console.log(errorData);
      throw new Error(`Network response was not ok: ${response.status}`);
    }

    const data = await response.json();
    const quizzes = data.data.quizzes;

    return quizzes;
  } catch (error) {
    throw error;
  }
};
export const saveQuizResponse = (data) => {
  return axiosInstance.post("/save_quiz_response", data);
};
export const getStatistics = () => {
  return axiosInstance.get("/get_vote_statistics");
};
export const getQuizResult = (id) => {
  return axiosInstance.get(`/get_quiz_response?quiz_id=${id}`);
};
