import React, { useEffect, useState } from "react";
import QuizLayout from "../../../components/layout/quizLayout/quizLayout";
import styles from "./questions.module.css";
import { FaAngleLeft } from "react-icons/fa";
import Questions from "../../../components/quiz/questions/questions";
import QuizSubmit from "../../../components/quiz/questions/submit";
import QuizResult from "../../../components/quiz/questions/result";
import { useNavigate, useParams } from "react-router-dom";
import { getQuizResult, getQuizzes, saveQuizResponse } from "../../../api";
const QuizQuestion = () => {
  const [stage, setStage] = useState("questions");
  const [response, setResponse] = useState([]);
  const [email, setEmail] = useState("");
  const [quiz, setQuiz] = useState();
  const [result, setresult] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isResultPage, setIsResultPage] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (params?.category && !params.quizId) {
      getQuizzes(params.category)
        .then((res) => {
          if (res?.length > 0) {
            setQuiz(res[0]);
          } else {
            navigate("/quiz");
          }
        })
        .catch((err) => console.log(err));
    } else if (params.quizId) {
      setStage("result");
      getQuizResult(params.quizId)
        .then((res) => {
          setIsResultPage(true);
          if (res?.data?.length > 0) {
            setresult(res?.data[0]?.response);
            console.log(res?.data?.response);
          } else {
            navigate("/");
          }
        })
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    }
  }, [params.category, params.quizId]);

  const submitQuiz = () => {
    const quizResponse = {
      email: email,
      quiz_responses: response,
    };
    setIsLoading(true);
    saveQuizResponse(quizResponse)
      .then((res) => {
        setresult(res?.data);
        setStage("result");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  return (
    <QuizLayout>
      {quiz || isResultPage ? (
        <>
          <div className={`${styles["question-head"]} container`}>
            <a className={styles["back"]} href="/quiz">
              <FaAngleLeft color="#ffffff" />
              All Quizzes
            </a>
            <h1>{quiz?.title}</h1>
          </div>
          <div className={`${styles["question-container"]}`}>
            <div className={`${styles["question"]} container`}>
              {stage === "questions" ? (
                <Questions
                  setStage={setStage}
                  result={response}
                  setResult={setResponse}
                  quiz={quiz}
                />
              ) : stage === "submit" ? (
                <QuizSubmit
                  setStage={setStage}
                  email={email}
                  setEmail={setEmail}
                  quiz={quiz}
                  submitQuiz={submitQuiz}
                  isLoading={isLoading}
                />
              ) : (
                <QuizResult
                  quiz={quiz}
                  result={result}
                  setStage={setStage}
                  isResultPage={isResultPage}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={`${styles["spinner"]} small-spinner-container`}>
          <div className="spinner"></div>
        </div>
      )}
    </QuizLayout>
  );
};

export default QuizQuestion;
