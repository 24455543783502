import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./magicLogin.module.css";
import { useNavigate } from "react-router-dom";
import { generateToken, magicLinkSignUp, verifyMagicLink } from "../../api";
import AuthContext from "../../store/authContext";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { Spinner } from "../../components/Popups/JoinKliqe/join_kliqe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Loading } from "react-loading-dot";
import ReCAPTCHA from "react-google-recaptcha";
import RedirectToInbox from "../../components/Popups/redirectToInbox/RedirectToInbox";
import { gtag, initDataLayer, install } from "ga-gtag";
const MagicLogin = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authStatus, setAuthStatus] = useState("Verifying");
  const authCtx = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(true);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [redirectToInbox, setRedirectToInbox] = useState(false);
  const captchaRef = useRef(null);
  const navigate = useNavigate();
  const handleSignupConversion = () => {
    initDataLayer();
    gtag("event", "conversion", {
      send_to: "AW-11321113499/uvpBCMWG54oZEJv3qZYq",
    });
    install("AW-11321113499");
  };
  const handleSubmit = async () => {
    if (!email) {
      setEmailError("Please fill all the fields");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email");
      return;
    }
    setIsLoading(true);
    try {
      const preferences = JSON.parse(localStorage.getItem("preferences")) || [];
      const tokenResponse = await generateToken(email);
      const reqBody = {
        email: email,
        recaptcha_token: captchaToken,
        topics_pro: preferences,
      };
      const response = await magicLinkSignUp(tokenResponse?.token, reqBody);
      captchaRef.current.reset();
      setCaptchaToken("");
      if (
        window.location.origin === "https://kliqe.com" &&
        response?.is_signup
      ) {
        handleSignupConversion();
      }
      setRedirectToInbox(true);
    } catch (error) {
      console.log("sign in error", error);
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    navigate("/");
    setIsOpen(false);
  };

  const handleAllOptions = () => {
    navigate("/login");
    setIsOpen(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleBlur = () => {
    if (!validateEmail(email) && email.length > 0) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  };

  const handleCaptchaChange = () => {
    setRecaptchaError(false);
    setCaptchaToken(captchaRef.current.getValue());
  };
  const handleGoBack = () => {
    setRedirectToInbox(false);
  };

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        const response = await verifyMagicLink(token);
        // console.log("response ==>", response);
        if (!response.error) {
          localStorage.setItem("refresh_token", response.refresh_token);
          window.postMessage(
            {
              type: "TriggerScore_Login",
              access: response.access_token,
              refresh: response.refresh_token,
            },
            "*"
          );
          authCtx.login(response.access_token);
          if (response?.initial_login) {
            localStorage.setItem("signup", true);
            localStorage.setItem("extension", true);
            if (response?.is_topic_selected) {
              localStorage.setItem("preferenceSet", true);
            } else {
              localStorage.setItem("preferenceVisit", true);
            }
            navigate("/welcome");
          } else {
            navigate("/preferences?q=true");
          }
        } else if (response.error === "expired") {
          setAuthStatus(response.error);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error(error);
      }
    };
    verifyToken();
  }, [navigate]);

  return authStatus === "Verifying" ? (
    <div style={{ height: "100vh" }}>
      <Spinner />
    </div>
  ) : (
    <div>
      <>
        {isOpen && (
          <div className={styles.popup}>
            <div className={`${styles.popupContent}`}>
              <button className={styles.popupClose} onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <h3>Oops, your link expired.</h3>
              <p className={`${styles.topDescription}`}>
                Enter your email address below and we will make a pre magic sign
                in link appear in your inbox. Just like that.
              </p>

              {!email ? <h6>Your Email </h6> : ""}
              <input
                className={styles.inputField}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={handleBlur}
              />
              {emailError && <p className={styles.emailError}>{emailError}</p>}
              <div className={styles.formControl}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                  onChange={handleCaptchaChange}
                />
                {recaptchaError && (
                  <span className={styles.submitErrorMsg}>
                    Please verify reCAPTCHA
                  </span>
                )}
              </div>
              <button
                className={styles.button}
                onClick={handleSubmit}
                disabled={isLoading || !captchaToken}
              >
                {isLoading ? (
                  <div className="loading-animation">
                    <div className="loading-animation">
                      <Loading background="white" size="5px" margin="5px" />
                    </div>
                  </div>
                ) : (
                  <span>Sign in</span>
                )}
              </button>
              <p className={`${styles.backToLogin}`} onClick={handleAllOptions}>
                <span className={styles.backArrow}></span>
                All sign in options
              </p>
            </div>
          </div>
        )}
        {redirectToInbox && (
          <RedirectToInbox
            handleClose={handleClose}
            handleGoBack={handleGoBack}
            email={email}
            label="Sign in"
          />
        )}
      </>
    </div>
  );
};

export default MagicLogin;
