import React from "react";
import QuizBanner from "../../quiz/banner/banner";
import NewNavbar from "../../NewNavbar/NewNavbar";
import styles from "./quizLayout.module.css";
const QuizLayout = ({ children }) => {


  return (
    <div className={styles["quiz"]}>
      <NewNavbar preference={true} />
      <QuizBanner />
      <div className={`${styles["quiz-container"]}`}>
        {children}
      </div>
    </div>
  );
};

export default QuizLayout;
